<template>
	<ViewLayout>
		<template #header-title>
			Footers
		</template>
		<template #header-caption>
			Add, edit and manage footers and translations
		</template>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<FootersTab
						:footers="footers"
						:table-headers="tableHeaders"
						:translation-table-headers="translationTableHeaders"
						:footer-translations="footerTranslations"
						:assigned-languages="assignedLanguagesItems"
						:can-create-update-footers="canCreateUpdateFooters"
						:footer-full-permissions="footerFullPermissions"
						@open-create-header-footer-form="openCreateHeaderFooterForm"
						@load-footers="loadFooters"
						@open-edit-header-footer-form="openEditHeaderFooterForm"
					/>
				</template>
				<template #1>
					<FootersTranslationsTab
						:translation-table-headers="translationTableHeaders"
						:footer-translations="footerTranslations"
						:assigned-languages="assignedLanguagesItems"
						:can-create-update-footers="canCreateUpdateFooters"
						:footer-full-permissions="footerFullPermissions"
						@load-footers="loadFooters"
					/>
				</template>
			</TabItems>
			<HeaderFooterFormModal
				v-if="showHeaderFooterForm"
				:form-to-edit="headerFooterFormData"
				form-type="Footer"
				@updated="loadFooters"
				@close="() => {
					showHeaderFooterForm = false
					headerFooterFormData = null
				}"
			/>
		</template>
	</ViewLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import Tabs from '../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../shared/components/tab-items.vue'
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
import FootersTab from './footers-tab.vue'
import FootersTranslationsTab from './footer-translations.vue'
import { getFooters, FOOTER, getFooterTranslations } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { useLabels } from '../../../../../../shared/state/admin-portal-navigation.js'
import { getAssignedLanguages } from '../../../../../../shared/utils/api/languages.js'
import { headersAndFootersTypeIdEnumOptions } from '../../../../../../shared/enums/headers-and-footers.js'
import HeaderFooterFormModal from '../../../shared/header-footer-form-modal.vue'
import { changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'
import { CAN_CREATE_UPDATE_FOOTERS, HEADERS_FOOTERS_MODULE_FULL_PERMISSIONS, COMPONENTS_MODULE_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'

export default {
	components: {
		HeaderFooterFormModal,
		FootersTab,
		FootersTranslationsTab,
		ViewLayout,
		TabItems,
		Tabs
	},
	setup () {
		return {
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			tabs: [{ title: 'Footers', slot: '0' }, { title: 'Footer Translations', slot: '1' }],
			tab: '0',
			footers: [],
			footerTranslations: [],
			assignedLanguages: [],
			showFilters: false,
			searchQuery: null,
			selectedBrandId: null,
			showHeaderFooterForm: false,
			headerFooterFormData: null,
			selectedFormType: null,
			selectedProductTypeId: null,
			selectedType: null,
			selectedLanguageId: null,
			headersAndFootersTypeIdEnumOptions,
			FOOTER
		}
	},
	computed: {
		...mapGetters({
			checkPermission: 'auth/productAreaPermission'
		}),
		canCreateUpdateFooters () {
			return this.checkPermission(CAN_CREATE_UPDATE_FOOTERS)
		},
		footerFullPermissions () {
			return this.checkPermission(HEADERS_FOOTERS_MODULE_FULL_PERMISSIONS || COMPONENTS_MODULE_FULL_PERMISSIONS)
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		tableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '10%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '10%'
				},
				{
					text: 'Footer Name',
					value: 'name',
					width: '20%'
				},
				{
					text: 'Product',
					value: 'typeDescription',
					width: '20%'
				},
				{
					text: 'Usage',
					value: 'usage',
					width: '20%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}
			]
		},
		translationTableHeaders () {
			return [
				{
					text: 'ID',
					value: 'id',
					width: '10%'
				},
				{
					text: useLabels('Brand'),
					value: 'brandName',
					width: '20%'
				},
				{
					text: 'Footer Name',
					value: 'name',
					width: '20%'
				},
				{
					text: 'Product',
					value: 'typeDescription',
					width: '20%'
				},
				{
					text: 'Translation Status',
					value: 'translationStatus',
					width: '10%'
				},
				{
					text: 'Created Date',
					value: 'createdDate',
					width: '10%'
				},
				{
					text: 'Action',
					value: 'action',
					width: '10%',
					sortable: false
				}
			]
		}
	},
	async created () {
		this.loadFooters()
		const { data: assignedLanguages } = await getAssignedLanguages()
		this.assignedLanguages = assignedLanguages
	},
	methods: {
		openEditHeaderFooterForm (item) {
			this.showHeaderFooterForm = true
			this.headerFooterFormData = item
		},
		openCreateHeaderFooterForm (formType) {
			this.showHeaderFooterForm = true
			this.selectedFormType = formType
		},
		async loadFooters () {
			const { data: footers } = await getFooters()
			const { footerTranslations } = await getFooterTranslations()
			this.footerTranslations = footerTranslations
			this.footers = footers
		}
	}
}
</script>
