<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="footerTextSearchQuery"
			search-query-label="Footer Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeFooterTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="selectedFooterProduct"
					label="Product"
					:items="headersAndFootersTypeDropdown"
					custom-sort
					@change="changeFooterProduct"
				/>
			</template>
			<template #action>
				<PrimaryActionButton
					v-if="canCreateUpdateFooters"
					@click="$emit('open-create-header-footer-form', FOOTER)"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				{{ 'Footers' | useLabels }}
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredFooters"
					sort-by="id"
					sort-desc
					@click:row="rowClick"
				>
					<template #item.typeDescription="{ item }">
						{{ footerType(item) }}
					</template>
					<template #item.usage="{ item }">
						{{ `${item.usage} ${footerType(item)}` }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							v-if="(item.brandId !== 0 || (item.brandId === 0 && userHasUniversalBrand)) && canCreateUpdateFooters"
							:tooltip-text="'Edit Footer' | useLabels"
							@click="rowClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							:tooltip-text="'View Footer' | useLabels"
							@click="rowClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="(item.brandId !== 0 || (item.brandId === 0 && userHasUniversalBrand)) && footerFullPermissions"
							tooltip-text="Delete Footer"
							@click.stop.prevent="footerIdToBeRemoved = item.id"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="footerIdToBeRemoved"
			:entity-name="footerToBeRemoved"
			entity-type="Footer"
			@close="footerIdToBeRemoved = null"
			@delete="deleteFooter"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
	deleteFooter,
	FOOTER
} from '../../../../../../shared/utils/api/headers-and-footers.js'
import {
	headersAndFootersTypeIdEnumOptions,
	headersAndFootersTypeDropdown
} from '../../../../../../shared/enums/headers-and-footers.js'
import DataTable from '../../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../shared/state/brands.js'
import { footerTextSearchQuery, changeFooterTextSearchQuery, selectedFooterProduct, changeFooterProduct } from '../../../../../../shared/state/footers.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'
import { sortDropdownWithNullDefault } from '../../../../utils/sorting/sortDropdownWithNullDefault.js'
export default {
	components: { SectionCard, DataTable, FilterBar, PrimaryActionButton, Dropdown, ConfirmDeleteModal, IconButton },
	props: {
		footers: {
			type: Array,
			default: () => []
		},
		tableHeaders: {
			type: Array,
			default: () => []
		},
		headersMetaData: {
			type: Array,
			default: () => []
		},
		footerFullPermissions: {
			type: Boolean,
			default: false
		},
		canCreateUpdateFooters: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			footerTextSearchQuery,
			changeFooterTextSearchQuery,
			selectedFooterProduct,
			changeFooterProduct,
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			headersAndFootersTypeIdEnumOptions,
			FOOTER,
			selectedProduct: null,
			footerIdToBeRemoved: null
		}
	},
	computed: {
		...mapGetters('auth', ['userHasUniversalBrand']),
		filteredFooters () {
			// need to add type in here when available
			return this.footers.filter(({ name, brandId, typeId, externalMetaData }) => {
				let check = true
				if (footerTextSearchQuery.value) check = name.toLowerCase().includes(footerTextSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (selectedFooterProduct.value !== null) check = check && typeId === selectedFooterProduct.value
				if (this.selectedMetaDataKey !== null) check = check && externalMetaData.some(meta => meta.externalDataId === this.selectedMetaDataKey)
				if (this.metaDataValueSearchQuery) {
					check = check &&
					externalMetaData.some(meta => meta.value.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
				}
				return check
			})
		},
		footerToBeRemoved () {
			return this.footers.find(({ id }) => id === this.footerIdToBeRemoved).name
		},
		headersAndFootersTypeDropdown () {
			return headersAndFootersTypeDropdown.sort(sortDropdownWithNullDefault)
		}
	},
	methods: {
		async deleteFooter () {
			await deleteFooter(this.footerIdToBeRemoved)
			this.footerIdToBeRemoved = null
			showSnackbar('Footer has been deleted')
			this.$emit('load-footers')
		},
		rowClick (row) {
			if (!this.canCreateUpdateFooters) {
				row.readOnly = true
			}
			row.brand = row.brandId
			this.$emit('open-edit-header-footer-form', row)
		},
		clearFilters () {
			selectedFooterProduct.value = null
		},
		footerType (item) {
			return this.headersAndFootersTypeIdEnumOptions[item.typeId]
		}
	}
}
</script>
