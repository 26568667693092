<template>
	<div class="cassie-vertical-md pt-2">
		<FilterBar
			:search-query.sync="footerTextSearchQuery"
			search-query-label="Footer Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			show-meta-data-filters
			:selected-meta-data-key-id.sync="selectedMetaDataKey"
			:meta-data-search-query.sync="metaDataValueSearchQuery"
			@persistSearchQuery="changeFooterTextSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@persistMetaDataSearchQuery="changeMetaDataValueSearchQuery"
			@persistSelectedMetaDataKeyId="changeMetaDataKey"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="showTranslatedOnly"
					:items="showTranslatedOnlyOptions"
					label="Translation Status"
					custom-sort
				/>
				<Dropdown
					v-model="selectedFooterProduct"
					label="Product"
					:items="headersAndFootersTypeDropdown"
					custom-sort
					@change="changeFooterProduct"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Footer Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Assigned Language"
					:items="assignedLanguages"
					@input="selectedLanguage = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="translationTableHeaders"
					:items="filteredFooterTranslation"
					sort-by="id"
					sort-desc
					no-data-text="No Footer Translations exist for the selected Language"
					@click:row="openTranslationModal"
				>
					<template #item.typeDescription="{ item }">
						{{ footerType(item) }}
					</template>
					<template #item.translationStatus="{ item }">
						{{ item.translatedHtml ? 'Translated' : 'Untranslated' }}
					</template>
					<template #item.action="{ item }">
						<IconButton
							tooltip-text="Open Footer Translation"
							@click="openTranslationModal(item)"
						>
							{{ canCreateUpdateFooters ? 'mdi-pencil': 'mdi-eye' }}
						</IconButton>
						<IconButton
							v-if="item.footerTranslationId && footerFullPermissions"
							tooltip-text="Delete Footer Translation"
							@click.stop.prevent="footerTranslationIdToBeRemoved = item.footerTranslationId"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<HeaderFooterTranslationModal
			v-if="showTranslationModal"
			:translation="footerTranslation"
			type="Footer"
			@close="closedModal"
		/>
		<ConfirmDeleteModal
			v-if="footerTranslationIdToBeRemoved"
			:entity-name="footerTranslationToBeRemoved"
			entity-type="Footer Translation"
			@close="footerTranslationIdToBeRemoved = null"
			@delete="deleteFooterTranslation"
		/>
	</div>
</template>
<script>
import { deleteFooterTranslation } from '../../../../../../shared/utils/api/headers-and-footers.js'
import { headersAndFootersTypeDropdown, headersAndFootersTypeIdEnumOptions } from '../../../../../../shared/enums/headers-and-footers.js'
import DataTable from '../../../../../../shared/components/data-table.vue'
import FilterBar from '../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import HeaderFooterTranslationModal from '.././header-footer-translation-modal.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../shared/state/brands.js'
import { footerTextSearchQuery, changeFooterTextSearchQuery, selectedFooterProduct, changeFooterProduct } from '../../../../../../shared/state/footers.js'
import { metaDataValueSearchQuery, selectedMetaDataKey, changeMetaDataKey, changeMetaDataValueSearchQuery } from '../../../../../../shared/state/meta-data.js'
export default {
	components: { SectionCard, DataTable, FilterBar, Dropdown, HeaderFooterTranslationModal, ConfirmDeleteModal, IconButton },
	props: {
		footerTranslations: {
			type: [Array, Object],
			default: () => []
		},
		assignedLanguages: {
			type: Array,
			default: () => []
		},
		translationTableHeaders: {
			type: Array,
			default: () => []
		},
		footerFullPermissions: {
			type: Boolean,
			default: false
		},
		canCreateUpdateFooters: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return {
			changeAdminPortalBrandFilter,
			selectedAdminPortalBrandId,
			footerTextSearchQuery,
			changeFooterTextSearchQuery,
			selectedFooterProduct,
			changeFooterProduct,
			metaDataValueSearchQuery,
			selectedMetaDataKey,
			changeMetaDataKey,
			changeMetaDataValueSearchQuery
		}
	},
	data () {
		return {
			headersAndFootersTypeDropdown,
			headersAndFootersTypeIdEnumOptions,
			selectedLanguage: null,
			footerTranslation: null,
			showTranslationModal: false,
			footerTranslationIdToBeRemoved: null,
			showTranslatedOnly: null
		}
	},
	computed: {
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguage != null) return this.selectedLanguage
			return this.assignedLanguages[0]?.value
		},
		filteredFooterTranslation () {
			const filteredTranslations = []
			this.footerTranslations.forEach(translations => {
				filteredTranslations.push(translations.filter(({ name, brandId, languageId, translatedHtml, typeId, metaData }) => {
					return this.checkTranslationFilters(name, brandId, languageId, translatedHtml, typeId, metaData)
				}))
			})
			return filteredTranslations.flat()
		},
		footerTranslationToBeRemoved () {
			return `${this.filteredFooterTranslation.find(({ footerTranslationId }) => footerTranslationId === this.footerTranslationIdToBeRemoved).name} Translation`
		},
		showTranslatedOnlyOptions () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Translated Only' },
				{ value: false, text: 'Untranslated Only' }
			]
		}
	},
	methods: {
		reloadTables () {
			this.$emit('load-footers')
		},
		closedModal (successfulOperation) {
			this.showTranslationModal = false
			if (successfulOperation) {
				this.reloadTables()
			}
		},
		async deleteFooterTranslation () {
			await deleteFooterTranslation(this.footerTranslationIdToBeRemoved)
			this.footerTranslationIdToBeRemoved = null
			showSnackbar('Footer Translation has been deleted')
			this.reloadTables()
		},
		openTranslationModal (footerTranslation) {
			if (!this.canCreateUpdateFooters && !footerTranslation.translatedHtml) return
			if (!this.canCreateUpdateFooters) {
				footerTranslation.readOnly = true
			}
			this.footerTranslation = footerTranslation
			this.showTranslationModal = true
		},
		checkTranslationFilters (name, brandId, languageId, translatedHtml, typeId, metaData) {
			let check = true
			check = languageId === this.selectedLanguageIdOrDefault
			if (footerTextSearchQuery.value) check = check && name.toLowerCase().includes(footerTextSearchQuery.value.toLowerCase())
			if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
			if (this.showTranslatedOnly !== null) check = check && (this.showTranslatedOnly && translatedHtml || !this.showTranslatedOnly && !translatedHtml)
			if (selectedFooterProduct.value !== null) check = check && typeId === selectedFooterProduct.value
			if (this.selectedMetaDataKey !== null) check = check && metaData.some(meta => meta.externalMetaDataId === this.selectedMetaDataKey)
			if (this.metaDataValueSearchQuery) check = check && metaData.some(meta => meta.externalDataValue.toLowerCase().includes(this.metaDataValueSearchQuery.toLowerCase()))
			return check
		},
		clearFilters () {
			this.showTranslatedOnly = null
			selectedFooterProduct.value = null
		},
		footerType (item) {
			return this.headersAndFootersTypeIdEnumOptions[item.typeId]
		}
	}
}
</script>
